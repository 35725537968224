import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Heading from "../components/heading/heading"
import SocialLinks from "../components/social-links"
import ContactForm from "../components/contact-form"
import LogoIcon from "../images/logos/icon-logo.inline.svg"
import MapMarker from "../images/mapMarker.inline.svg"
import Email from "../images/email.inline.svg"
import Phone from "../images/phone.inline.svg"

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <Helmet bodyAttributes={{ class: "overflow-x-hidden" }} />
    <div className="contact-wrapper">
      <div className="row">
        <div className="col-sm-12 col-md-7">
          <LogoIcon className="logo-icon" style={{ marginBottom: 15 }} />
          <Heading
            title="We'd love to hear from you"
            copy="We’d love to hear from you and tell you more about our revolutionary platform. Let us help you leave behind the days of a closed, unresponsive monolithic point-of-sale for the freedom of a holistic commerce platform."
            styleKey="secondary"
          />
        </div>
        <div className="col-sm-12 col-md-4 offset-md-1">
          <ContactForm />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-3">
          <div className="icon-block">
            <MapMarker />
            <p>
              <a
                href="https://www.google.com/maps/place/234+Morrell+Rd+%23312,+Knoxville,+TN+37919/@35.9256228,-84.0336845,19z/data=!3m1!4b1!4m5!3m4!1s0x885c24b94e3e9ec9:0x3e251b64e4e4f20b!8m2!3d35.9256217!4d-84.0331373"
                target="_blank"
                rel="noreferrer"
              >
                234 Morrell Rd<br></br>
                #312<br></br>
                Knoxville, TN 37919
              </a>
            </p>
          </div>

          <div className="icon-block">
            <Phone />
            <p>
              <a href="tel:8449513037">(844) 951-3037</a>
            </p>
          </div>

          <div className="icon-block">
            <Email />
            <p>
              <a href="mailto:info@retailstack.com">info@retailstack.com</a>
            </p>
          </div>

          <div className="social-list d-flex align-items-center">
            <SocialLinks />
          </div>
        </div>
        <div className="col-sm-12 col-md-9 contactMap">
          <div className="imageContainer"></div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Contact
