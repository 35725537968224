import React from 'react'

const ContactForm = () => {

  return (
    <>
      <form name="contact" method="post" action="/success" data-netlify="true" data-netlify-honeypot="bot-field">
        <div className="form-row">
          <div className="form-group col-sm-12">
            <input type="text" name="name" placeholder="Name" className="form-control" required />
          </div>
          <div className="form-group col-sm-12">
            <input type="email" name="email" placeholder="Email" className="form-control" required />
          </div>
          <div className="form-group col-sm-12">
            <select className="form-control" name="industry">
              <option selected disabled value="">Industry</option>
              <option value="General Retail">General Retail</option>
              <option value="Restaurant">Restaurant</option>
              <option value="C-Store">C-Store</option>
              <option value="Dispensed Fuel">Dispensed Fuel</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="form-group col-sm-12">
            <input type="text" name="subject" placeholder="Subject" className="form-control" required />
          </div>
          <div className="form-group col-sm-12">
            <textarea name="message" className="form-control" rows="6" placeholder="Message" ></textarea>
          </div>
          <div style={{display: 'none'}}>
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
          </div>
        </div>

        <button type="submit" className="btn btn-primary" style={btnStyle}>Request</button>
      </form>
    </>
  )
}

export default ContactForm

const btnStyle = {
  float: 'right',
  marginTop: '10px'
};